import { BackToHomePageButton } from "./BackToHomePageButton"

export const Info = (): JSX.Element => {
    return (
        <div>
            <BackToHomePageButton />
            <h3>Tietoja ja käyttöehdot</h3>
            <div style={{textAlign: 'left'}}>
                <h4>Tietoja kuvapalvelusta</h4>
                <ul>
                    <li>Tämä kuvapalvelu on kehitetty innostuksesta ja mielenkiinnosta lintuihin, lintukuvaukseen ja ohjelmointiin.</li>
                    <li>Palvelu on avattu käyttöön helmikuussa 2022. Tämän palvelun kehitys ja ylläpito ei ole sidoksissa mihinkään 
                        yhdistykseen tai yritykseen vaan on yksittäisen henkilön projekti.</li>
                    <li>Lista lintulajeista noudattaa BirdLife Suomen ylläpitämää luetteloa Suomessa havaituista lintulajeista.</li>
                    <li>Yhteys: info@suomenlinnutkuvina.fi</li>
                </ul>
                <h4>Käyttöehdot</h4>
                <ol>
                    <li>Palveluun saa lisätä vain linnuista otettuja kuvia.</li>
                    <li>Kuvan lisääjällä tulee olla tekijänoikeudet kuvaan tai muussa tapauksessa lupa kuvan käyttämiseen.</li>
                    <li>Lisäämällä kuvan palveluun käyttäjä säilyttää kuvan tekijänoikeudet täysin itsellään, mutta sallii
                        niiden näyttämisen palvelussa.</li>
                    <li>Lisäämänsä kuvan voi poistaa palvelusta milloin vain.</li>
                    <li>Poistamalla käyttäjätilinsä poistaa automaattisesti myös kaikki lisäämänsä kuvat.</li>
                    <li>Kuvapalvelun ylläpitäjä ei vastaa käyttäjien ilmoittamista tiedoista kuviin liittyen.</li>
                    <li>Käyttöehtoja rikkova käyttäjätili voidaan poistaa palvelusta ilman erillistä ilmoitusta.</li>
                </ol>
            </div>
        </div>
    )
}