import {Box, Button} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {IBird} from '../App'
import { BackToHomePageButton } from './BackToHomePageButton'

interface IBirdsProps {
  birds: IBird[]
}

export function Birds(props: IBirdsProps): JSX.Element {
    const birds = props.birds?.sort(function(a: IBird, b: IBird) {
      if  (a.nameFin > b.nameFin) {
        return 1
      }
      return -1
    })
  
    return (
      <Box my={4}>
        <h2>Lintulajit aakkosjärjestyksessä</h2>
        <BackToHomePageButton />
        {birds.length > 0 ? birds.map((bird: IBird, i: number) => 
          <Link to={'/birds/'+bird.nameScientific} key={i} > 
            <Button>
              {bird.nameFin}
            </Button>
          </Link>) 
          : 
          <div className="Loader"></div>
        }
      </Box>
    )
  }