import React, {useState, useEffect} from 'react';
import './App.css';
import {Container} from '@material-ui/core';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Cookies from 'universal-cookie'
import {Home} from './components/Home'
import {Login} from './components/Login'
import {MyImages} from './components/MyImages'
import {AddImage} from './components/AddImage'
import {Birds} from './components/Birds'
import {Bird} from './components/Bird'
import { UserImages } from './components/UserImages';
import {Taxonomicrank} from './components/TaxonomicRank'
import {Header} from './components/Header'
import { LatestImages } from './components/LatestImages';
import { Register } from './components/Register';
import { MyInformation } from './components/MyInformation';
import { Footer } from './components/Footer';
import { DataPrivacy } from './components/DataPrivacy';
import { Info } from './components/Info';

export interface IBird {
  family: string,
  order: string,
  genus: string,
  nameFin: string,
  nameScientific: string
}

export interface ITaxonomicRank {
  nameScientific: string,
  nameFin: string,
  level: string,
  parent: string
}

export interface IAuthenticatedUser {
  name: string,
  email: string
}

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080'
const COOKIE_NAME = 'BL_AUTH'
const COOKIE_EXPIRATION_TIME = 24*60*60*1000
const RETRY_TIMES = 5

function App(): JSX.Element {
  const cookies = new Cookies()

  const [birds, setBirds] = useState<IBird[]>([])
  const [taxonomicRanks, setRanks] = useState<ITaxonomicRank[]>([])
  const [username, setUsername] = useState<string>('')
  const [authUser, setAuthUser] = useState<IAuthenticatedUser>()

  useEffect(() => {getBirds()}, [])
  useEffect(() => {getTaxonomicRanks()}, [])
  useEffect(() => {fetchAuthUser()}, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const getBirds = async (): Promise<void> => {
    const url = BASE_URL + '/api/bird'
    let index = 0
    while (index < RETRY_TIMES) {
      try {
        const res = await fetch(url)
        if (res.ok) {
          const birds = (await res.json()) as IBird[]
          setBirds(birds)
          return
        } 
      } catch (err) {undefined}
      index++
    }
  }

  const getTaxonomicRanks = async (): Promise<void> => {
    const url = BASE_URL + '/api/taxonomicrank'
    let index = 0
    while (index < RETRY_TIMES) {
      try {
        const res = await fetch(url)
        if (res.ok) {
          const ranks = (await res.json()) as ITaxonomicRank[]
          setRanks(ranks)
          return
        }
      } catch (err) {undefined}
      index++
    }
  }

  const fetchAuthUser = async (): Promise<void> => {
    const token: string = cookies.get(COOKIE_NAME)
    const url = BASE_URL + '/api/user/authenticated'
    if (token) {
      let index = 0
      while (index < RETRY_TIMES) {
        try {
          const res: Response = await fetch(url, {
            headers: {'Authorization': `Bearer ${token}`}
          })
          if (res.ok) {
            const authUser = (await res.json()) as IAuthenticatedUser
            setUsername(authUser.name)
            setAuthUser(authUser)
            return
          }
        } catch (err) {undefined}
        index++
      }
    }
    setUsername('')
    setAuthUser(undefined)
  }

  return (
    <div className="App">
      <Router>
        <Header cookie_name={COOKIE_NAME} username={username} setUsername={setUsername} setAuthUser={setAuthUser} />
        <Container maxWidth="md">
            <Route exact path="/" render={() => <Home authenticated={username} />} />
            <Route exact path="/birds" render={() => <Birds birds={birds} />} />
            <Route exact path="/birds/:id" render={({ match }) => 
              <Bird bird={birds?.find((bird) => 
                bird.nameScientific === match.params.id) } base_url={BASE_URL} />} 
            />
            <Route exact path="/taxonomicrank" render={() => 
              <Taxonomicrank taxonomicRanks={taxonomicRanks} birds={birds} />}
            />
            <Route exact path="/taxonomicrank/:order" render={({ match }) => 
              <Taxonomicrank taxonomicRanks={taxonomicRanks} birds={birds} selectedOrderName={match.params.order} />} 
            />
            <Route exact path="/taxonomicrank/:order/:family" render={({ match }) => 
              <Taxonomicrank taxonomicRanks={taxonomicRanks} birds={birds} selectedOrderName={match.params.order} 
                selectedFamilyName={match.params.family} />} 
            />
            <Route exact path="/taxonomicrank/:order/:family/:genus" render={({ match }) => 
              <Taxonomicrank taxonomicRanks={taxonomicRanks} birds={birds} selectedOrderName={match.params.order} 
                selectedFamilyName={match.params.family} selectedGenusName={match.params.genus} />} 
            />
            <Route exact path="/login" render={() => <Login authenticated={username} fetchUsername={fetchAuthUser} 
              cookie_name={COOKIE_NAME} cookie_expiration_time={COOKIE_EXPIRATION_TIME} base_url={BASE_URL} />} />
            <Route exact path="/addimage" render={() => <AddImage cookie_name={COOKIE_NAME} base_url={BASE_URL} birds={birds} />} />
            <Route exact path="/myimages" render={() => <MyImages cookie_name={COOKIE_NAME} base_url={BASE_URL} username={username} birds={birds} />} />
            <Route exact path="/user/:username" render={({match}) =><UserImages base_url={BASE_URL} username={match.params.username} birds={birds}/>} />
            <Route exact path="/latestimages" render={() =><LatestImages base_url={BASE_URL} birds={birds}/>} />
            <Route exact path="/register" render={() => <Register base_url={BASE_URL} cookie_name={COOKIE_NAME} fetchUsername={fetchAuthUser} 
              cookie_expiration_time={COOKIE_EXPIRATION_TIME} />} />
            <Route exact path="/myinformation" render={() => <MyInformation authUser={authUser} base_url={BASE_URL} cookie_name={COOKIE_NAME} 
              setUsername={setUsername} setAuthUser={setAuthUser} />} />
            <Route exact path="/dataprivacy" render={() => <DataPrivacy/>}/>
            <Route exact path="/info" render={() => <Info/>}/>
        </Container>
        <Footer/>
      </Router>
    </div>
  )
}


export default App;
