import {Button, Menu, MenuItem} from '@material-ui/core';
import {useState} from 'react';
import {Link, useHistory} from 'react-router-dom'
import Cookies from 'universal-cookie'
import { IAuthenticatedUser } from '../App'

interface IHeaderProps {
    cookie_name: string,
    username: string,
    setUsername:  React.Dispatch<React.SetStateAction<string>>
    setAuthUser: React.Dispatch<React.SetStateAction<IAuthenticatedUser | undefined>>
}

export const Header = (props: IHeaderProps): JSX.Element => {
    const cookies = new Cookies()
    const onLogout = (): void => {
        cookies.remove(props.cookie_name, {path: '/'})
        props.setUsername('')
        props.setAuthUser(undefined)
        history.push('/')
    }

    const history = useHistory()
    const onLogin = (): void => {
        history.push('/login')
    }

    const onAdd = (): void => {
        history.push('/addimage')
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
      }
    
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDataprivacyClicked = (): void => {
        setAnchorEl(null)
        history.push('/dataprivacy')
    }

    const handleInfoClicked = (): void => {
        setAnchorEl(null)
        history.push('/info')
    }

    return (
        <div style={{border: '1px solid', margin: 0, padding: 0, width: '100%', display: 'flex', marginBottom: '30px'}}>
            <div className="HeaderButtonDiv">
                <Button className="HeaderButton" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                    Info
                </Button>
                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                    <MenuItem onClick={handleInfoClicked}>Tietoja ja käyttöehdot</MenuItem>
                    <MenuItem onClick={handleDataprivacyClicked}>Tietosuoja</MenuItem>
                </Menu>

            </div>
            <div className="HeaderTopic">
                <h2><Link style={{color: 'black', fontFamily: 'Overpass'}} to="/" >Suomen Linnut Kuvina </Link></h2>
            </div>
            <div className="HeaderButtonDiv">
                {props.username ? <Button className="HeaderButton" onClick={onAdd}>Lisää uusi kuva</Button> : <div/>}
                {props.username ? <Button className="HeaderButton" onClick={onLogout}>Kirjaudu ulos</Button> : 
                <Button className="HeaderButton" onClick={onLogin}>Kirjaudu / Luo tili</Button>}
            </div>
        </div>
    )
}