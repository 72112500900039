import { useEffect, useState } from 'react'
import { Button } from '@material-ui/core';
import { IBird } from '../App'
import { Image, IImageMetadata, IImageMetadataList } from './Image'
import { BackToHomePageButton } from './BackToHomePageButton'

interface ILatestImagesProps {
    base_url: string,
    birds: IBird[]
}

export function LatestImages(props: ILatestImagesProps): JSX.Element {

    const [imageMetadatas, setImageMetadatas] = useState<IImageMetadata[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPageCount, setPageCount] = useState<number>()
    const [fetching, setFetching] = useState<boolean>(false)
    const [latestAdded,setLatestAdded] = useState<boolean>(true)
    const RETRY_TIMES = 5

    useEffect(() => {
        const fetchLatestImageMetadatas = async (): Promise<void> => {
            setFetching(true)
            let index = 0
            while (index < RETRY_TIMES) {
                const url = props.base_url + `/api/image/latest?page=${page}&sortBy=${latestAdded ? 'added' : 'taken'}`
                try {
                    const res: Response = await fetch(url)
                    if (res.ok) {
                        const metadatas = (await res.json() as unknown) as IImageMetadataList
                        setImageMetadatas(metadatas.metadataDTOList)
                        setPageCount(metadatas.totalPageCount)
                        setFetching(false)
                        return
                    }
                } catch (err) {undefined}
                index++
            }
            setFetching(false)
        }
        
        fetchLatestImageMetadatas()
    }, [props.base_url, page, latestAdded])

    const FilterButtons = (): JSX.Element => {
        return (
            <div style={{margin:'10px'}}>
                <p>Kuvien järjestys:</p>
                <Button onClick={() => setLatestAdded(true)} variant={latestAdded ? 'contained': 'text'}>Viimeksi lisätyt</Button>
                <Button onClick={() => setLatestAdded(false)} variant={latestAdded ? 'text': 'contained'}>Viimeksi kuvatut</Button>
            </div>
        )
    }

    return (
        <div>
            <BackToHomePageButton />
            <FilterButtons />
            {fetching && <div className="Loader"></div>}
            {imageMetadatas && imageMetadatas.map((metadata, i) => 
                <Image key={i} base_url={props.base_url} bird={props.birds.find(b => b.nameScientific === metadata.bird)} 
                    metadata={metadata} index={i} lastImage={i === imageMetadatas.length-1} showOwner={true}/>
            )}
            <div>
                {page > 0 && <Button onClick={() => setPage(page-1)} variant='contained'>Takaisin</Button>}
                {!!totalPageCount && totalPageCount-1 > page && <Button onClick={() => setPage(page+1)} variant='contained'>Aiemmat</Button> }
            </div>
        </div>
    )
}