import {Button} from '@material-ui/core';
import {useEffect, useState} from 'react';
import Cookies from 'universal-cookie'
import {IImageMetadata, IImageMetadataList, Image} from './Image'
import {IBird} from '../App'
import { BackToHomePageButton } from './BackToHomePageButton'
import { ImageInfoBox } from './ImageInfoBox';

interface IMyImagesProps {
    base_url: string,
    cookie_name: string,
    username: string,
    birds: IBird[]
}

export interface IStatistics {
    imageCountTotal: number,
    imageCountThisYear: number,
    birdSpeciesAll: string[],
    birdSpeciesThisYear: string[]
}

export function MyImages(props: IMyImagesProps): JSX.Element {
    const username = props.username
    const cookies = new Cookies()
    const [metadatas, setMetadatas] = useState<IImageMetadata[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPageCount, setPageCount] = useState<number>()
    const [myStatistics, setMyStatistics] = useState<IStatistics>()
    const [latestAdded, setLatestAdded] = useState<boolean>(true)

    useEffect(() => { 
        const fetchUserImageMetadatas = async (user: string): Promise<void> => {
            const url = props.base_url + `/api/image/user/${user}?page=${page}&sortBy=${latestAdded ? 'added' : 'taken'}`
            const res: Response = await fetch(url)
            if (res.ok) {
                const metadatas = (await res.json() as unknown) as IImageMetadataList
                setMetadatas(metadatas.metadataDTOList)
                setPageCount(metadatas.totalPageCount)
            } else {
                console.error('Response not ok.')
            }
        }

        const fetchUserStatistics = async (user: string): Promise<void> => {
            const url = props.base_url + `/api/user/${user}/statistics`
            const res: Response = await fetch(url)
            if (res.ok) {
                const statistics = (await res.json()) as IStatistics
                setMyStatistics(statistics)
            }
        }
        fetchUserImageMetadatas(props.username)
        fetchUserStatistics(props.username)
    }, [props.username, props.base_url, page, latestAdded])

    const removeImage = (id: number): void => {
        const url = props.base_url + '/api/image/' + id
        fetch(url, {
            method: 'delete',
            headers: {'Authorization': `Bearer ${cookies.get(props.cookie_name)}`}
        })
        .then((res) => {
            if (res.ok) {
                return true
            } else {
                throw new Error('Response not ok.')
            }
        })
        .then(() => setMetadatas(metadatas.filter(meta => meta.id !== id)))
        .catch(error => console.error(error))
    }

    const FilterButtons = (): JSX.Element => {
        return (
            <div style={{margin:'10px'}}>
                <p>Kuvien järjestys:</p>
                <Button onClick={() => setLatestAdded(true)} variant={latestAdded ? 'contained': 'text'}>Viimeksi lisätyt</Button>
                <Button onClick={() => setLatestAdded(false)} variant={latestAdded ? 'text': 'contained'}>Viimeksi kuvatut</Button>
            </div>
        )
    }

    return (
      <div>
        <BackToHomePageButton />
        <ImageInfoBox username={username} statistics={myStatistics} myinformation={true} birds={props.birds}/>
        <FilterButtons />
        <div>
            {metadatas.map((meta) => <Image key={meta.id} metadata={meta} base_url={props.base_url} removeImage={removeImage} 
                bird={props.birds.find(b => b.nameScientific === meta.bird)}/>)}
        </div>
        <div>
            {page > 0 && <Button onClick={() => setPage(page-1)}>Takaisin</Button>}
            {!!totalPageCount && totalPageCount-1 > page && <Button onClick={() => setPage(page+1)}>Seuraava sivu</Button> }
        </div>
      </div>
    )
  }