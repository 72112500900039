import { BackToHomePageButton } from "./BackToHomePageButton"

export const DataPrivacy = (): JSX.Element => {
    return (
        <div>
            <BackToHomePageButton />
            <h3>Tietosuoja</h3>
            <div style={{textAlign: 'left'}}>
                <ul>
                    <li>Palvelu käyttää evästeitä vain välttämättömiin tarkoituksiin käyttäjän kirjautuessa. Muita evästetietoja ei käytetä.</li>
                    <li>Käyttäjän poistaessa palvelusta aiemmin itse lisäämänsä kuvan se poistuu lopullisesti eikä tietoja kuvasta jätetä palveluun.</li>
                    <li>Käyttäjän poistaessa käyttäjätilinsä kaikki tiliin liittyvät tiedot poistetaan palvelun tietokannasta.</li>
                    <li>Palvelun tallentamat kuvat ja tiedot säilytetään Google Cloud Platformin Suomessa sijaitsevilla palvelimilla.</li>
                    <li>Kaikki verkkoliikenne käyttäjän selaimen ja palvelimen välillä on salattua HTTPS-protokollaa käyttäen.</li>
                </ul>
            </div>
        </div>
    )
}