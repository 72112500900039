import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input} from '@material-ui/core';
import Cookies from 'universal-cookie'
import { FormInputElement } from './FormInputElement';

interface IRegisterProps {
    base_url: string
    cookie_expiration_time: number
    cookie_name: string
    fetchUsername: () => void
}

export function Register(props: IRegisterProps): JSX.Element {

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordAgain, setPasswordAgain] = useState<string>('')
    const [email, setEmail] = useState<string>('')

    const [usernameValid, setUsernameValid] = useState<boolean>(true)
    const [passwordValid, setPasswordValid] = useState<boolean>(true)
    const [passwordAgainValid, setPasswordAgainValid] = useState<boolean>(true)
    const [emailValid, setEmailValid] = useState<boolean>(true)

    const [sending, setSending] = useState<boolean>(false)
    const [somethingWentWrong, setSomethingWentWrong] = useState<boolean>(false)

    const history = useHistory()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        setSending(true)
        if (await validateUsername() && validatePassword() && validatePasswordAgain() && validateEmail()) {
            const res: Response = await fetch(props.base_url + '/api/user', 
                {method: 'post', 
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: email, enabled: true, username: username, password: password})
            })
            if (res.ok) {
                const authRes: Response = await fetch(props.base_url + "/auth", {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({username: username, password: password})
                })
                if (authRes.ok) {
                    const authResBody = await authRes.json()
                    const cookies = new Cookies()
                    const exp = new Date();
                    exp.setTime(exp.getTime() + props.cookie_expiration_time)
                    cookies.set(props.cookie_name, authResBody.token, {path: '/', expires: exp})
                    props.fetchUsername()
                }
                history.push('/')
            } else {
                setSomethingWentWrong(true)
                setSending(false)
            }
        } else {
            setSending(false)
        }
    }

    const handleFormDataChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        switch (event.target.name) {
            case 'username' :
                setUsername(event.target.value)
                break
            case 'password' :
                setPassword(event.target.value)
                break
            case 'passwordAgain' :
                setPasswordAgain(event.target.value)
                break
            case 'email' :
                setEmail(event.target.value)
                break
        }
    }

    const validateUsername = async (): Promise<boolean> => {
        const trimmedUsername = username.replaceAll(' ', '')
        setUsername(trimmedUsername)
        const usernameRegex = new RegExp(/^\S{3,}/)
        if (username === undefined || !usernameRegex.test(trimmedUsername) || username.length > 40) {
            setUsernameValid(false)
            return false
        }
        const res: Response = await fetch(props.base_url + '/api/user/' + trimmedUsername + '/exists')
        if (res.ok) {
            const usernameInUse: boolean = await res.json()
            if (!usernameInUse) {
                setUsernameValid(true)
                return true
            }
        }
        setUsernameValid(false)
        return false;
    }

    const validatePassword = (): boolean => {
        const trimmedPassword = password.replaceAll(' ', '')
        setPassword(trimmedPassword)
        const passRegex = new RegExp('^(?=.{6,})')
        if (password !== undefined && passRegex.test(trimmedPassword)) {
            setPasswordValid(true)
            return true
        } else {
            setPasswordValid(false)
            return false
        }
    }

    const validatePasswordAgain = (): boolean => {
        if (password === passwordAgain) {
            setPasswordAgainValid(true)
            return true
        } else {
            setPasswordAgainValid(false)
            return false
        }
    }

    const validateEmail = (): boolean => {
        const emailRegex = new RegExp(/^\S+@\S+\.\S+$/)
        if (email !== undefined && emailRegex.test(email)) {
            setEmailValid(true)
            return true
        } else {
            setEmailValid(false)
            return false
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <h2>Luo uusi käyttäjätili</h2>
            <div>Käyttäjätunnuksen täytyy olla yksilöllinen. Käyttäjätunnus ja käyttäjän lisäämät kuvat ovat kaikille julkisia. Sähköpostiosoite ei ole julkinen tieto eikä siten näy muille käyttäjille.</div>
            <FormInputElement elementLabel='Käyttäjätunnus:' disabled={sending} type='text' name='username' value={username} onBlur={validateUsername} onChange={handleFormDataChange} />
            <FormInputElement elementLabel='Salasana:' disabled={sending} type='password' name='password' value={password} onBlur={validatePassword} onChange={handleFormDataChange}/>
            <FormInputElement elementLabel='Salasana uudestaan:' disabled={sending} type='password' name='passwordAgain' onBlur={validatePasswordAgain} value={passwordAgain} onChange={handleFormDataChange}/>
            <FormInputElement elementLabel='Sähköpostiosoite:' disabled={sending} type='text' name='email' value={email} onBlur={validateEmail} onChange={handleFormDataChange} />
            <div style={{clear: 'both'}}/>
            <div style={{visibility: usernameValid && passwordValid && passwordAgainValid && emailValid ? 'hidden' : 'visible'}} className="ErrorBox">
                {!usernameValid && <p>Käyttäjätunnuksen täytyy olla vähintään kolme kirjainta ja maksimissaan 40 kirjainta pitkä eikä se voi olla jo käytössä.</p>}
                {!passwordValid && <p>Tarkista salasana! Salasanan on oltava vähintään kuusi merkkiä pitkä.</p>}
                {!passwordAgainValid && <p>Tarkista, että olet antanut saman salasanan kahdesti.</p>}
                {!emailValid && <p>Sähköpostiosoite ei ole kelvollinen!</p>}
                {somethingWentWrong && <p>Jokin meni vikaan. Yritä uudelleen.</p>}
            </div>
            <div style={{marginTop: '20px'}}>
                <Input type="submit" disabled={!usernameValid || !passwordValid || !passwordAgainValid || !emailValid || sending} name="submit-button" value="Luo tili" />
            </div>
        </form>
    )
}