import {Input} from '@material-ui/core';

  interface IFormInputElementProps {
    elementLabel: string,
    type: 'text' | 'password',
    name: string,
    value: string | number,
    disabled?: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  }

  export const FormInputElement = (props: IFormInputElementProps): JSX.Element => {
    return (
      <div className="FormElement">
          <p className="FormInputElementLabel">{props.elementLabel}</p>
          <Input style={{float: 'left', width: '100%'}} disabled={props.disabled} type={props.type} name={props.name} value={props.value} onBlur={props.onBlur} onChange={props.onChange} />
      </div>
    )
  }