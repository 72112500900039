import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import Cookies from 'universal-cookie'
import Alert from '@material-ui/lab/Alert'
import { IAuthenticatedUser } from '../App'
import { useState } from 'react'
import { FormInputElement } from './FormInputElement'

interface IMyInformationProps {
    authUser?: IAuthenticatedUser,
    base_url: string,
    cookie_name: string,
    setUsername:  React.Dispatch<React.SetStateAction<string>>,
    setAuthUser: React.Dispatch<React.SetStateAction<IAuthenticatedUser | undefined>>
}

const BackToMyImages = (): JSX.Element => {
    return (
        <Link to="/myimages" className="Link">
            <Button style={{marginBottom: '20px', fontFamily: 'Overpass, sans serif'}} fullWidth={true} variant='outlined'>Palaa omiin kuviin</Button>
        </Link>
    )
}

export function MyInformation(props: IMyInformationProps): JSX.Element {
    const [remove, setRemove] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [sending, setSending] = useState<boolean>(false)
    const [accountRemoved, setAccountRemoved] = useState<boolean>(false)
    const [somethingWentWrong, setSomethingWentWrong] = useState<boolean>(false)

    const removeAccount = async () => {
        setSending(true)
        const cookies = new Cookies();
        const res: Response = await fetch(props.base_url + '/api/user/delete', 
                    {method: 'post', 
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${cookies.get(props.cookie_name)}`},
                    body: password
                })
        setSending(false)
        if (res.ok) {
            cookies.remove(props.cookie_name, {path: '/'})
            props.setUsername('')
            props.setAuthUser(undefined)
            setAccountRemoved(true)
            setSomethingWentWrong(false)
        } else {
            setSomethingWentWrong(true)
        }
    }

    return (
        <div>
            {!accountRemoved && <BackToMyImages/>}
            <div>
                <p>Käyttäjätunnus: {props.authUser ? props.authUser.name : ''}</p>
                <p>Sähköposti: {props.authUser ? props.authUser.email : ''}</p>
                {!remove && !accountRemoved &&
                <Button onClick={() => {setRemove(true)}}>Poista tili</Button>}
                {accountRemoved && <h4>Tämä tili on poistettu käytöstä.</h4>}
            </div>
            {remove && !accountRemoved &&
            <Alert variant="filled" severity="warning" onClose={() => {setRemove(false)}}>
                Haluatko varmasti poistaa tilin? Tilin kaikki kuvat poistetaan. Poistaaksesi tilin, anna tilin salasana ja paina kyllä.
                <FormInputElement elementLabel='Salasana:' disabled={sending} type='password' name='password' value={password} 
                    onChange={(e) => setPassword(e.target.value)}/>
                <Button disabled={sending} onClick={() => {removeAccount()}}>Kyllä</Button>
            </Alert>}
            {somethingWentWrong && 
                <h4>Tilin poistaminen ei onnistunut! Tarkista salasana.</h4>
            }
        </div>
    )
}