import { Box, Button, Link } from "@material-ui/core";
import { useState } from "react";
import { IBird } from "../App";
import { IStatistics } from "./MyImages";

interface IImageInfoBoxProps {
    statistics?: IStatistics,
    username: string,
    myinformation: boolean,
    birds: IBird[]
}

export function ImageInfoBox(props: IImageInfoBoxProps): JSX.Element {

    const [allSpeciesHidden, setAllSpeciesHidden] = useState<boolean>(true)
    const [speciesThisYearHidden, setSpeciesThisYearHidden] = useState<boolean>(true)

    const getFinNames = (species: string[]) => {
        return species.map(specie => props.birds.find(bird => bird.nameScientific === specie))
            .map(bird => bird ? bird.nameFin : '')
            .filter(nameFin => nameFin.length > 0)
            .sort(function(a, b) {
                if (a > b) {
                    return 1
                }
                return -1
            }) 
    }

    const Species = (speciesProps: {speciesList: string[], hidden: boolean}): JSX.Element => {
        const speciesListFin = speciesProps.speciesList.reduce((previous, current) => 
            (previous ? previous + ', ' : '') + current, '')

        return (
            <div>
                {speciesProps.hidden ? '' : speciesListFin}
            </div>
        )
    }

    const birdSpeciesAll: string[] = props.statistics ? 
        getFinNames(props.statistics.birdSpeciesAll) : []

    const birdSpeciesThisYear: string[] = props.statistics ?
        getFinNames(props.statistics.birdSpeciesThisYear) : []


    const arrowDown = <>&#8595;</>
    const arrowRight = <>&#8594;</>

    return (
        <Box margin={5}>
            <p>Käyttäjänimi: {props.username}</p>
            <p>Kuvia yhteensä: {props.statistics?.imageCountTotal}</p>
            <p>Kuvia tältä vuodelta: {props.statistics?.imageCountThisYear}</p>
            <p>Eri lajeja yhteensä: {props.statistics?.birdSpeciesAll.length}
                <Button onClick={() => setAllSpeciesHidden(allSpeciesHidden ? false : true)}>
                    {allSpeciesHidden ? arrowRight : arrowDown}</Button></p>
            <Species speciesList={birdSpeciesAll} hidden={allSpeciesHidden}/>
            <p>Eri lajeja tältä vuodelta: {props.statistics?.birdSpeciesThisYear.length}
                <Button onClick={() => setSpeciesThisYearHidden(speciesThisYearHidden ? false : true)}>
                    {speciesThisYearHidden ? arrowRight : arrowDown}</Button></p>
            <Species speciesList={birdSpeciesThisYear} hidden={speciesThisYearHidden}/>
            {props.myinformation && <p><Link href='/myinformation'>Muut omat tiedot</Link></p>}
        </Box>
    )
}